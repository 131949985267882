<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <s-toolbar dark color="primary" label="Ingreso de Pallet a camara"></s-toolbar>
                    </v-card-title>
                    <v-card-text>
                        <v-row justify="center" style="margin:auto">
                            <v-col
                                align="center"
                                cols="12"
                                lg="12"
                                md="12"
                            >   
                                <!-- <h1><b>{{objTunnelCamera.CdtDescripcion}}</b></h1> -->
                                <s-scanner-qr
                                    :config="configScann"
                                    return-object 
                                    @onValue="onValue($event)"
                                    :readonly="false"
                                    :autofocus="false"
                                    :clearInput="clearInput"
                                    v-model="PalletGroupID"
                                    >

                                </s-scanner-qr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <s-select
                                    :items="listChamber"
                                    label="Camaras"
                                    placeholder="Digite Usuario"
                                    item-text="CdtDescription"
                                    item-value="CdtID"
                                    v-model="SelectChamber"
                                    return-object                                    
                                    @input="click($event)">
                                </s-select>
                            </v-col>
                            <v-col>
                                <s-select
                                    clearable
                                    :items="SelectChamber.tunnelsDetails"
                                    label="Lado"
                                    placeholder="Digite Usuario"
                                    item-text="SideDescription"
                                    item-value="CddID"
                                    v-model="SelectSide"
                                    return-object                                    
                                    @input="clickSide($event)">
                                </s-select>
                            </v-col>                                
                        </v-row>   
                        <v-row justify="center" style="margin:auto">
                            <v-col  cols="auto" >
                                <s-select-definition
                                    label = "Tipo de Ingreso"
                                    :def="1456"
                                    v-model="itemHead.TypeEntry"
                                >

                                </s-select-definition>
                            </v-col>
                            <v-col  cols="auto" >
                                <s-select-client
                                    clearable
                                    label="Cliente(es):"
                                    v-model="CumID"
                                    ref="txtCumID"
                                    full 
                                    @input="filterPallets()">
                                </s-select-client>      
                            </v-col>
                            <v-col cols="auto">
                                <s-select-brands
                                    clearable
                                    label="Marcas"
                                    v-model="CbdID"
                                    ref="txtBrands"
                                    :cumID="CumID"
                                    full 
                                    @input="filterBrands()">
                                </s-select-brands>   
                            </v-col>
                            <v-col cols="auto">                              
                                <s-select-definition
                                    clearable
                                    label="Tipo corte"
                                    :def="1429"
                                    v-model="TypeCut"
                                    @input="filterPallets()"
                                ></s-select-definition>    
                            </v-col>
                            <v-col cols="auto">
                                <s-select-definition
                                    clearable
                                    label="Tipo Empaque"
                                    v-model="TypePacking"
                                    :def="1435"
                                    @input="filterPallets()"
                                ></s-select-definition>
                            </v-col>
                            <v-col cols="auto">
                                <s-select 
                                    clearable
                                    label="Carril"
                                    item-text="Name"
                                    item-value="Index"
                                    :items="carriles"
                                    v-model="FilterCarril"
                                    @input="filterPallets()">

                                </s-select>
                            </v-col>
                            <!-- <v-col cols="auto">
                                <s-select-definition
                                    clearable
                                    label="Tipo de Congelamiento"
                                    :def="1439"
                                    v-model="TypeFreezing"                 
                                    @input="filterPallets()"
                                    >
                                </s-select-definition>
                            </v-col> -->
                        </v-row>
                                             
                        <v-row v-show="isShow">
                            <v-col cols="auto" v-for="(item, indexNumberLanes) in SelectSide.NumberLanes" 
                                                        :key="indexNumberLanes" :value="item"  >
                                <v-card theme="dark" hover >
                                    <p hidden v-if="FilterCarril > 0" > {{ indexNumberLanes = FilterCarril - 1 }}</p>
                                    <v-toolbar class="flex-grow-0" dark color="#5edf87" height="30"  rounded >
                                        <v-toolbar-title>{{ 'Carril ' +  parseInt(item + SelectSide.NumberStart)}}</v-toolbar-title>
                                    </v-toolbar>
                                        
                                    <v-card-text>
                                        <v-row dense v-for="(nivel, indexNumberLevels) in SelectSide.NumberLevels" 
                                                        v-bind:key="indexNumberLevels" :value="nivel">
                                            <v-col  cols="auto"  class="py-1 pe-0">
                                                <v-card hover color="#f4edeb" >
                                                    <v-toolbar color="#299ff9" height="30" shaped >
                                                        <v-toolbar-title>{{ Position[indexNumberLevels]}}</v-toolbar-title>
                                                    </v-toolbar>
                                                    
                                                    <v-row class="mt-1" dense>
                                                        <v-col cols="auto"  v-for="(posicion, indexPositionsLevel) in SelectSide.PositionsLevel" 
                                                            v-bind:key="indexPositionsLevel" :value="posicion">
                                                            <v-card  v-if="SelectChamber.Pallest?.filter((x) =>  
                                                                        x.SideID == SelectSide.SideID
                                                                        && x.NumberLanes === indexNumberLanes 
                                                                        && x.NumberLevels === indexNumberLevels
                                                                        && x.PositionsLeve === indexPositionsLevel).length == 1"
                                                                
                                                                :color="colorOcupado" 
                                                                hover     
                                                                @click="detailPalletInPosition(SelectSide.SideID, indexNumberLanes, indexNumberLevels, indexPositionsLevel)"                                                            
                                                                >
                                                                <v-card-text  >                                                                    
                                                                    {{  posicion }}                                                                                                                                                                                                                                                                   
                                                                </v-card-text>                                                                                                                      
                                                            </v-card>

                                                            <v-card  v-else
                                                                
                                                                :color="indexNumberLanes == selectNumberLane
                                                                        && indexNumberLevels == selectNumberLevel 
                                                                        && indexPositionsLevel == selectPositionsLevel ? '#7bc6fe' : '#f0faf7'" 
                                                                hover 
                                                                @click="Probar(indexNumberLanes, indexNumberLevels, indexPositionsLevel)"
                                                                >
                                                                <v-card-text>
                                                                    {{  posicion }}                                                                        
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                    
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>                            
                            </v-col>
                        </v-row>
                        <v-row v-show="isFilter">
                            <v-col cols="auto" v-for="(item, indexNumberLanes) in SelectSide.NumberLanes" 
                                                        :key="indexNumberLanes" :value="item" v-show="PaletsFilters?.filter((x) =>  
                                                                                     x.NumberLanes === indexNumberLanes).length > 0" >
                                <v-card theme="dark" hover >                                       
                                    <v-toolbar class="flex-grow-0" dark color="#5edf87" height="30"  rounded >
                                        <v-toolbar-title>{{ 'Carril ' +  parseInt(item + SelectSide.NumberStart)}}</v-toolbar-title>
                                    </v-toolbar>
                                        
                                    <v-card-text>
                                        <v-row dense v-for="(nivel, indexNumberLevels) in SelectSide.NumberLevels" 
                                                        v-bind:key="indexNumberLevels" :value="nivel">
                                            <v-col  cols="auto"  class="py-1 pe-0">
                                                <v-card hover color="#f4edeb" >
                                                    <v-toolbar color="#299ff9" height="30" shaped >
                                                        <v-toolbar-title>{{ Position[indexNumberLevels]}}</v-toolbar-title>
                                                    </v-toolbar>
                                                    
                                                    <v-row class="mt-1" dense>
                                                        <v-col cols="auto"  v-for="(posicion, indexPositionsLevel) in SelectSide.PositionsLevel" 
                                                            v-bind:key="indexPositionsLevel" :value="posicion">
                                                            <v-card  v-if="SelectChamber.Pallest?.filter((x) =>  
                                                                                x.SideID == SelectSide.SideID
                                                                                && x.NumberLanes === indexNumberLanes 
                                                                                && x.NumberLevels === indexNumberLevels
                                                                                && x.PositionsLeve === indexPositionsLevel).length == 1"
                                                                disabled
                                                                :color="colorOcupado" 
                                                                hover 
                                                                
                                                                >
                                                                <v-card-text>
                                                                    {{  posicion }}                                                                        
                                                                </v-card-text>
                                                            </v-card>

                                                            <v-card  v-else
                                                                
                                                                :color="indexNumberLanes == selectNumberLane
                                                                        && indexNumberLevels == selectNumberLevel 
                                                                        && indexPositionsLevel == selectPositionsLevel ? '#7bc6fe' : '#f0faf7'" 
                                                            hover 
                                                            @click="Probar(indexNumberLanes, indexNumberLevels, indexPositionsLevel)"
                                                                >
                                                                <v-card-text>
                                                                    {{  posicion }}                                                                        
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                    
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>                            
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <v-card-actions class="px-0 contendor-card">
                        <v-col cols="auto">
                            <v-btn small  color="primary" class="btn-flotante" dark @click="SaveNewPallet()">
                                <v-icon left small>fas fa-save</v-icon> Guardar
                            </v-btn>  
                        </v-col>            
                    </v-card-actions>                   
                </v-card>
            </v-col>
        </v-row>  
        <v-dialog
            v-model="dialogDetallePalletInPosition"
            v-if="dialogDetallePalletInPosition"
            persistent
            width="unset"
            
        >
            <detail-pallet 
                :sider= sider
                :lane = lane
                :level = level
                :pst = pst
                :CdtID = this.SelectChamber.CdtID
                @closeDetallePalletInPosition="closeDetallePalletInPosition()">
            </detail-pallet>
        </v-dialog>      
    </v-container>
</template>
<script>

    import _sChamber from '@/services/FrozenProduction/FrzChamberDispatchTunnelsChamber.js';
    import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import SSelectBrands from "@/components/FrozenProduction/Chamber/SSelectBrandChamber";
    import DetailPallet from './FrzChamberViewGraphics/FrzChamberDetailPalletInPosition.vue';
    export default{
        components: { SSelectClient, SSelectBrands, DetailPallet  },
        data(){       
             return {
                listChamber:[],
                SelectChamber: {},
                SelectSide: {},
                Position: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
                selectNumberLane: null,
                selectNumberLevel: null,
                selectPositionsLevel: null,
                configScann: {},
                clearInput: false, 
                itemHead:{},
                CumID: 0,
                colorOcupado: 'red accent-2',
                isShow : true,
                isFilter: false,
                PaletsFilters: [],
                TypeCut: 0,
                TypePacking: 0,
                CbdID: 0,
                dialogDetallePalletInPosition: false,
                sider : 0,
                lane : 0,
                level : 0,
                pst : 0,
                carriles: [],
                PalletGroupID: '',
                FilterCarril: 0,
                NumberLanesOld: 0,
                NumberStartOld: 0,
            }
        },
        methods:{
            SaveNewPallet(){
                let val = this.PalletGroupID;                
                this.onValue(val);
            },
            closeDetallePalletInPosition(){
                this.dialogDetallePalletInPosition = false;
            },
            detailPalletInPosition(sider,lane,level,pst){
                
                this.dialogDetallePalletInPosition = true
                this.sider = sider;
                this.lane = lane;
                this.level = level;
                this.pst = pst;    
                        
            },
            filterBrands(){
                this.filterPallets();
            },
            filterPallets()
            {     
                
                if(this.SelectSide.SideID == undefined && (this.CumID != null || this.TypeCut != null || this.TypePacking != null))
                {
                    this.$fun.alert("Por Favor Seleccione Lado para poder filtrar", "warning");
					return;
                }      

                if(this.FilterCarril > 0 && this.FilterCarril != null)
                {         
                    
                    this.isShow = true;
                    this.isFilter = false;
                    this.SelectSide.NumberLanes = 1;
                    this.PaletsFilters = [];
                    this.SelectSide.NumberStart = this.FilterCarril - 1;
                  
                }
                if(this.CumID != 0 && this.CumID != null){
                 
                    this.isShow = false;
                    this.isFilter = true;
                    this.PaletsFilters = [];
                    this.PaletsFilters = this.SelectChamber.Pallest.filter((r) =>                    
                        r.CdtID == this.SelectSide.CdtID                           
                        && r.SideID == this.SelectSide.SideID                         
                        && r.CumID == this.CumID                                    
                    );  
                }

                if(this.CbdID != 0 && this.CbdID != null)
                {
                    this.isShow = false;
                    this.isFilter = true;
                    if(this.PaletsFilters.length > 0)
                    {
                        this.PaletsFilters = this.PaletsFilters.filter((r) => 
                            r.CbdID == this.CbdID
                        ); 
                    }else{
                        this.PaletsFilters = this.SelectChamber.Pallest.filter((r) =>                    
                            r.CdtID == this.SelectSide.CdtID                           
                            && r.SideID == this.SelectSide.SideID                         
                            && r.CbdID == this.CbdID                                    
                        );  
                    }                    
                }
                
                if(this.TypeCut != 0 && this.TypeCut != null)
                {
                    this.isShow = false;
                    this.isFilter = true;
                    if(this.PaletsFilters.length > 0)
                    {
                        this.PaletsFilters = this.PaletsFilters.filter((r) => 
                            r.TypeCut == this.TypeCut
                        ); 
                    }else{
                        this.PaletsFilters = this.SelectChamber.Pallest.filter((r) =>                    
                            r.CdtID == this.SelectSide.CdtID                           
                            && r.SideID == this.SelectSide.SideID                         
                            && r.TypeCut == this.TypeCut                                    
                        );  
                    }
                    
                }

                if(this.TypePacking != 0 && this.TypePacking != null)
                {
                    this.isShow = false;
                    this.isFilter = true;
                    if(this.PaletsFilters.length > 0)
                    {
                        this.PaletsFilters = this.PaletsFilters.filter((r) => 
                            r.TypePacking == this.TypePacking
                        ); 
                    }else{
                        this.PaletsFilters = this.SelectChamber.Pallest.filter((r) =>                    
                            r.CdtID == this.SelectSide.CdtID                           
                            && r.SideID == this.SelectSide.SideID                         
                            && r.TypePacking == this.TypePacking                                    
                        );  
                    }                    
                }
                
                if((this.CumID == 0 || this.CumID == null) 
                    && (this.TypeCut == 0 || this.TypeCut == null)
                    && (this.TypePacking == 0 || this.TypePacking == null)
                    && (this.CbdID == 0 || this.CbdID == null)
                    && (this.FilterCarril == 0 || this.FilterCarril == null))
                {
                    this.isShow = true;
                    this.isFilter = false;
                    // this.listChamber = [];   
                    // this.SelectSide = {};       
                    this.initialize();  
                }     
                if(this.FilterCarril == null)
                {  
                    this.carriles = [];
                    this.SelectSide.NumberLanes = this.NumberLanesOld;
                    this.SelectSide.NumberStart = this.NumberStartOld;  
                    
                    for(let i = this.SelectSide.NumberStart + 1; i <= this.SelectSide.NumberLanes + this.SelectSide.NumberStart; i++)
                    {
                        let carril = {}
                        carril.Index = i;
                        carril.Name = 'Carril ' + i;
                        this.carriles.push(carril);
                    } 
                    this.initialize();   
                }         
            },
            initialize(){
                _sChamber.list({CdtWarehouses: 1}, this.$fun.getUserID()).then((r) => {
                    if(r.status = 200)
                    {
                        this.listChamber = r.data;                   
                        // this.SelectChamber = this.listChamber[0];
                        // this.SelectSide = this.SelectChamber.tunnelsDetails[0];                      
                    }
                });
            },
            click(SelectChamber){
                this.FilterCarril = 0;
                this.SelectSide = {};    
                this.PaletsFilters = [];   
           
                     
            },
            clickSide(side){
                this.isShow = true;
                this.isFilter = false;
                this.PaletsFilters = [];
                this.FilterCarril = 0;
                this.carriles = [];
                this.SelectSide = side;
                this.NumberLanesOld = this.SelectSide.NumberLanes;
                this.NumberStartOld = this.SelectSide.NumberStart;
                for(let i = side.NumberStart + 1; i <= side.NumberLanes + side.NumberStart; i++)
                {
                    let carril = {}
                    carril.Index = i;
                    carril.Name = 'Carril ' + i;
                    this.carriles.push(carril);
                } 
              
                // this.filterPallets();
            },
            saveUbicacion()
            {
                
                
            },
            Probar(item, nivel, posicion){    
                if(this.FilterCarril > 0)
                {
                    item = this.FilterCarril - 1;
                }            
                this.selectNumberLane = item;
                this.selectNumberLevel = nivel;
                this.selectPositionsLevel = posicion;
                
                 
            },
            onValue(val)
            {
                let item = {};
                item.CumID = this.CumID;
                item.CdtID = this.SelectChamber.CdtID;
                
                if(item.CdtID == undefined)
                {
                    this.$fun.alert("Por Favor Seleccione Camara ", "warning");
					return;
                }

                if(this.SelectSide.SideID == undefined)
                {
                    this.$fun.alert("Por Favor Seleccione Lado ", "warning");
					return;
                }
                
                if(this.selectNumberLane == null)
                {
                    this.$fun.alert("Por Favor Seleccione un Carril ", "warning");
					return;
                }
                if(this.selectNumberLevel == null)
                {
                    this.$fun.alert("Por Favor Seleccione un Nivel ", "warning");
					return;
                }
                if(this.selectPositionsLevel == null)
                {
                    this.$fun.alert("Por Favor Seleccione una Posicion ", "warning");
					return;
                }
                // item.PapID = val;
                item.PalletGroupID = val;
                item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();

                item.SideID = this.SelectSide.SideID;
                item.NumberLanes = this.selectNumberLane;
                item.NumberLevels = this.selectNumberLevel;
                item.PositionsLeve = this.selectPositionsLevel;
                item.TypeEntry = this.itemHead.TypeEntry;

                let chambername = "";
                chambername = this.SelectChamber.CdtDescription;  
                if(chambername.length > 0)              
                    item.PalletLocation = (chambername.substring(0, 3) + ' ' + chambername.slice(-1) + ' ' + 'CARRIL ' + parseInt(this.selectNumberLane + 1 + this.SelectSide.NumberStart)+ '/' +  this.Position[this.selectNumberLevel] + parseInt(this.selectPositionsLevel + 1 ));
                
                // return                   
                _sFrzCameraIncomePallet.save(item, this.$fun.getUserID())
                .then( r => {
                    if(r.status == 200)
                    {                        
                        this.$fun.alert("Pallet ingresado correctamente", "success");
                        this.SelectChamber = {};
                        this.initialize();
                    }
                });
            }
        },
        created(){
            this.initialize();
        }
    }
</script>
<style>
.resaltado {
    background-color: red;
}
.btn-flotante {
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	border-radius: 5px; /* Borde del boton */
	letter-spacing: 2px; /* Espacio entre letras */
	background-color: #E91E63; /* Color de fondo */
	padding: 18px 30px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante:hover {
	background-color: #2c2fa5; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 14px;
		padding: 12px 20px;
		bottom: 20px;
		right: 20px;
	}
} 

</style>
